.appointment-title {
    padding-top: 3rem;
    padding-bottom: 3rem;

    /* border: 5px dotted rgb(255,0,0); */
    /* border: 5px dotted rgb(255,0,0); */
  
  }
  
  .appointment-title>h1 {
    font-size: 5rem;
  
  }

  .appointment {

    display: flex;
    align-items: center;
    flex-direction: column;
    padding-bottom: 100px;
  
  }
  

.error-message {
    font-family: "Roboto", sans-serif;
    font-size: 15px;
    color: red;
    margin-top: 15px;
    align-items: center;
    width: 100%;
  
  
  }
  
  .success-message {
    font-size: 2.5rem;
    color: green;
    margin-bottom: 25px;
    padding: 1rem;
    text-align: center;
  }
  
  .form-container {
    border: 5px solid #000;
    padding: 20px;
  }
  
  .form-field {
    margin: 10px 0 10px 0;
    padding: 15px;
    font-size: 16px;
    border: 0;
    font-family: "Roboto", sans-serif;
    background: #c0c0c0;
    width: auto;
    border: #000;
  
  
  }
  
  .appointment-form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* border: 5px dotted rgb(255,0,0); */
  }
  
  .submit-button {
    background: rgb(0, 192, 0);
    color: white;
    cursor: pointer;
    height: 3rem;
    font-size: 1.5rem;
  }
  
  .area-label {
    margin: 20px 0 5px 0;
  }
  
  button:disabled {
    cursor: default;
  }
  

  @media screen and (max-width: 800px) {
    .appointment-title  {
        padding: 1.5rem;
        /* border: 5px dotted rgb(255,0,0); */
        /* border: 5px dotted rgb(255,0,0); */
      
      }

    .appointment-title >h1 {
        font-size: 3rem;
      
      }

      .success-message {
        font-size: 2rem;
        color: green;
        margin-bottom: 25px;
      
      }

  }