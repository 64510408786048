

  
  .cancer {
    color: rgb(192, 0, 0);
  }
  
  
  .services-title {
    padding: 3rem;
    /* border: 5px dotted rgb(255,0,0); */
    /* border: 5px dotted rgb(255,0,0); */
  
  }
  
  .services-title>h1 {
    font-size: 5rem;
    text-align: center;
  }

  .service-list>h2 {
    text-align: center;
    font-size: 3rem;
    padding-bottom: 1rem;
    /* border: 5px dotted rgb(255,0,0); */
    margin-left: 20px;
    margin-right: 20px;
  
  }
  
  
  .service {
    font-size: 2rem;
    /* border: 5px dotted rgb(255,0,0); */
    margin: 1rem;
  }
  
  
  .content {
    flex-direction: row;
    /* border: 5px dotted rgb(255,0,0); */
    display: flex;
    width: 100%;
  
    padding-bottom: 100px;
  
  
  }


  .list {
    /* border: 5px dotted rgb(255,0,0); */
    width: 50%;
  }
  
  .left {
    border-right: 2px solid #000;

  }
  
  .right {
    border-left: 2px solid #000;
  }
  
  .service-list {
    /* border: 5px dotted rgb(255,0,0); */
    width: 50%;

  }
  

  .extended-list {
    display:flex;

  }

  @media screen and (max-width: 1400px) {
      

      
  .list {
    width: 100%;

  }
    .extended-list {
        flex-direction: column;
      }
    
  }

  @media screen and (max-width: 800px) {

    .services-title  {
        padding: 1.5rem;
        /* border: 5px dotted rgb(255,0,0); */
        /* border: 5px dotted rgb(255,0,0); */
      
      }

    .services-title >h1 {
        font-size: 3rem;
      
      }

      .service-list>h2 {
        font-size: 1.5rem;
        padding-bottom: 0.5rem;
        /* border: 5px dotted rgb(255,0,0); */
      
      }

      .service {
        font-size: 1rem;
        /* border: 5px dotted rgb(255,0,0); */
        margin: 0.5rem;
      }
  }
