
.btn {
    padding: 8px 20px;
    border-radius: 20px;
    outline: none;
    border: none;
    cursor: pointer;
}

.btn--black {
    background-color: transparent;
    color: #000;
    padding: 8px 20px;
    border: 3px solid #000;
    transition: all 0.3s ease-out;
}

.btn--white {
    background-color: transparent;
    color: #fff;
    padding: 8px 20px;
    border: 3px solid #fff;
    transition: all 0.3s ease-out;
}

.btn--medium {
    padding: 8px 20px;
    font-size: 20px;
}

.btn--large {
    padding: 12px 24px;
    font-size: 3rem;
}

.btn--white:hover {
    background: #fff;
    color: #000;
    transition: all 0.3s ease-out;
}

.btn--black:hover {
    background: #000;
    color: #fff;
    transition: all 0.3s ease-out;
}

@media screen and (max-width: 650px) {



    .btn--large {
        padding: 8px 16px;
        font-size: 2rem;
    }
}

@media screen and (max-width: 650px) {

    .btn--large {
        padding: 6px 12px;
        font-size: 1.5rem;
    }
}