
  .team-title {
    padding: 3rem;
    /* border: 5px dotted rgb(255,0,0); */
    /* border: 5px dotted rgb(255,0,0); */
  
  }

  .team-title>h1 {
    font-size: 5rem;
    text-align: center;

  }


.members {
    display: flex;
    flex-direction: column;
  }
  
  .member {
    display: flex;
    flex-direction: row;
    padding-bottom: 100px;
  }
  
  .member > p {
    font-size: 1.6rem;
    padding-right: 40px;
  }
  
  .image-description {
    padding-left: 40px;
    padding-right: 40px;
    display: flex;
    flex-direction: column;
    /* border: 5px dotted rgb(255,0,0); */

  }
  
  .description {
    font-size: 2.5rem;
      /* border: 5px dotted rgb(255,0,0); */
  }
  
  .employee-category {
    padding-bottom: 50px;
    font-size: 3rem;
  }
  
  .pic {
    width: 350px;
  }
  


  @media screen and (max-width: 800px) {
    .member {
        flex-direction: column;
    }

    .member > p {
        padding-left: 40px;
        padding-top: 40px;
      }

    .pic {
    }

    .image-description {
        align-items: center;

            padding-left: 20px;
            padding-right: 20px;
    }

    .team-title {
        padding: 2rem;
        /* border: 5px dotted rgb(255,0,0); */
        /* border: 5px dotted rgb(255,0,0); */
      
      }
    
      .team-title>h1 {
        font-size: 4rem;
      
      }


  }