.navbar {
  background: #000;
  height: 120px;
  position: sticky;
  z-index: 999;
  top: 0;
  width: 100%

}

.navbar-container {

  height: 120px;
  display: flex;
  flex-grow: 1;

}

.left-side {
  display: flex;
  flex: 1 1 auto;
  justify-content: center;
  flex-direction: column;
  margin-left: 40px;

}

.specialty {
  color: #fff;
  font-size: 2rem;
  text-decoration: none;
  align-self: stretch;
  display: flex;
  justify-content: center;

}



.navbar-title {
  color: #fff;
  cursor: pointer;
  text-decoration: none;
  font-size: 3rem;
  align-self: flex-start;

}

.center-side {
  color: #fff;
  flex: 100 100 auto;
  display: flex;
  justify-content: flex-end;
}


.right-side {
  flex: 1 1 auto;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-content: space-around;
  padding: 40px;
}

.telephone-container {
  color: #fff;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  flex-direction: row;
  padding-bottom: 5px;
}


.phone-number {
  color: #fff;
  text-decoration: none;
  display: flex;

}

.fa-phone {
  color: #fff;
  padding: 5px;
}



.map-container {
  color: #fff;
  font-size: 2rem;
  display: flex;

}

.address {
  color: #fff;
  text-decoration: none;
  display: flex;

}

.address-text {
  font-size: 1.5rem;

}

.phone-text {
  font-size: 2rem;


}

.fa-map-marker {
  color: #fff;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
}


.showMenu {
  display: flex;
  transition: all 0.5s ease;

}

.nav-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: absolute;
  top: 120px;
  transition: all 0.5s ease;
  background: #000;
  left: 0;
  z-index: 1;
}


.flex {
  display: flex;


}

.nav-menu {
  display: flex;
  list-style: none;

}

.nav-item {
  display: flex;

}

.nav-links {

  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  font-size: 1.5rem;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;

}


.nav-links:hover {
  border-bottom: 4px solid #fff;
  transition: all 0.2s ease-out;
}

.nav-options:hover {
  background: #fff;
  color: #000;
  transition: 250ms;
}


.fa-bars {
  color: #fff;
}



.menu-icon {
  display: none;
  justify-content: center;
  align-items: center;
  padding: 15px;
}




.wide {
  display: flex
}

@media screen and (max-width: 1400px) {
  .menu-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px
  }

  .nav-links {
    color: #fff;
    text-decoration: none;
    font-size: 2rem;
    padding: 1.5rem;
    justify-content: center;
    display: flex;
    flex-grow: 1;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  }

  .nav-links:hover {
    background: #fff;
    color: #000;
    transition: 250ms;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: absolute;
    top: 120px;
    transition: all 0.5s ease;
    background: #000;
    left: 0;
    z-index: 1;
  }

  .right-side {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

  }

  .fa-map-marker {
    color: #fff;
    padding: 25px;
    font-size: 4rem;
  }

  .hide {
    display: none;
  }

  .fa-phone {
    color: #fff;
    padding: 5px;
    font-size: 4rem;
    padding: 25px
  }

  .wide {
    display: none
  }

  .fa-times {
    font-size: 5rem;
  }

  .fa-bars {
    font-size: 5rem;
  }

  .telephone-container {
    padding-bottom: 0px;

  }

}

/* @media screen and (max-width: 780px) {

  .navbar-title {
    font-size: 3rem;
  }

 


  .fa-phone {
    font-size: 3rem;
    padding: 15px
  }

  .fa-times {
    font-size: 3rem;
  }

  .fa-bars {
    font-size: 3rem;
  }


  .fa-map-marker {
    font-size: 3rem;
    padding: 15px;

  }

  .right-side {
    padding: 20px;
  }

  .menu-icon {
    padding: 5px
  }

  .nav-links {
    font-size: 1.5rem;
      padding: 1rem;

  }

} */


@media screen and (max-width: 800px) {

  .left-side {
    margin-left: 20px;

  }

  .navbar-title {
    font-size: 2rem;
    margin-left: 0px;

  }

  .specialty {
    font-size: 1.5rem;

  }


  .fa-phone {
    font-size: 2rem;
    padding: 10px
  }

  .fa-times {
    font-size: 2rem;
  }

  .fa-bars {
    font-size: 2rem;
  }


  .fa-map-marker {
    font-size: 2rem;
    padding: 10px;

  }

  .right-side {
    padding: 10px;
  }

  .menu-icon {
    padding: 5px
  }

  .nav-links {
    font-size: 1rem;
    padding: 0.5rem
  }

}


@media screen and (max-width: 450px) {
  .navbar {
    height: 80px;
  }

  .navbar-container {
    height: 80px;
  }

  .navbar-title {
    font-size: 1.7rem;
  }

  .specialty {
    font-size: 1.2rem;

  }
  
  .nav-list {
    top: 80px
  }

  .nav-menu {
    top: 80px
  }
}

@media screen and (max-width: 410px) {


  .navbar-title {
    font-size: 1.5rem;
  }

  .specialty {
    font-size: 1rem;

  }

  .fa-phone {
    font-size: 1.5rem;
    padding: 5px
  }

  .fa-times {
    font-size: 1.5rem;
  }

  .fa-bars {
    font-size: 1.5rem;
  }


  .fa-map-marker {
    font-size: 1.5rem;
    padding: 5px;

  }

  .right-side {
    padding-left: 5px;
  }

  .navbar {
    height: 60px;
  }

  .navbar-container {
    height: 60px;
  }

  
  .nav-list {
    top: 60px
  }

  .nav-menu {
    top: 60px
  }

}


